import React from "react"

import SEO from "../components/theme/header/seo"
import Layout from "../components/theme/layout"
import CartDrawer from "../components/drawers/cart-drawer"

// ======================
//        COMPONENT
// ======================
const CartPage = ({ pageContext, location }) => {
  const title = "Cart"

  return (
    <Layout pathContext={pageContext} location={location}>
      <SEO title={title} location={location} yoast={{}} />
      <CartDrawer isCartPage location={location} />
    </Layout>
  )
}

export default CartPage
